export const router_store = {
  namespaced: true,
  state: {
    routerViewKey: null,
  },
  getters: {
    routerViewKey: state => state.routerViewKey,
  },
  actions: {
    changeRouterViewKey({ commit }, payload) {
      commit('changeRouterViewKey', payload);
    },
  },
  mutations: {
    changeRouterViewKey(store, payload) {
      store.routerViewKey = payload;
    },
  }
};