export const online_list_main_table_settings = {
  namespaced: true,
  state: {
    groupingType: 'platformType',
  },
  getters: {
    groupingType: state => state.groupingType,
  },
  actions: {
    changeGrouping({ commit }, payload) {
      commit('changeGrouping', payload);
    },
    
  },
  mutations: {
    changeGrouping(state, payload) {
      state.groupingType = payload;
    },
  }
}