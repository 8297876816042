// set layouts
import Default from '@/layouts/Default.vue';
import DefaultNew from '@/layouts/DefaultNew.vue';
import Restyle from '@/layouts/restyleLayout.vue';
import RestyleMobile from '@/layouts/restyleMobileLayout.vue';
import NoSidebar from '@/layouts/NoSidebar.vue';
import Empty from '@/layouts/Empty.vue';

//init global components
import btnPreload from '@/components/Preloaders/btn-preload';
import Alert from '@/components/Alert';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import Loader from '@/components/Loader';
import Modal from '@/components/Modal/Modal';
import ModalConfirm from '@/components/Modal/ModalConfirm';
import ModalTrash from '@/components/Modal/ModalTrash';
import ModalCustom from '@/components/Modal/ModalCustom';
import Popover from '@/components/Popover';
import Tooltip from '@/components/Tooltip';
import ProgressBar from '@/components/ProgressBar';
import Switchery from '@/components/Switchery';
import Panel from '@/components/Panel';
import Tabs from '@/components/Tabs/Tabs';
import Tab from '@/components/Tabs/Tab';
import Pagination from '@/components/Pagination';
import portalPagination from '@/components/helpers/pagination';
import List from '@/components/List/List';
import ListItem from '@/components/List/ListItem';
import restyle_button from "@/components/restyle/primitives/buttons/restyle_button";
import restyle_alert from "@/components/restyle/alert/restyle_alert";
import PaginationRestyle from '@/components/restyle/pagination/PaginationRestyle';
import IconWasteType from '@/components/restyle/primitives/icons/IconWasteType';
import icon_button from '@/components/restyle/primitives/buttons/icon_button';
import switch_checkbox from '@/components/restyle/primitives/checkboxes/switch_checkbox';
import RestyleInput from '@/components/restyle/primitives/inputs/RestyleInput';
import RestyleSelect from '@/components/restyle/primitives/select/RestyleSelect';
import RestyleModal from '@/components/restyle/modal/RestyleModal';
import RestyleTooltip from '@/components/restyle/primitives/tooltips/RestyleTooltip';
import RestyleTextarea from '@/components/restyle/primitives/textarea/RestyleTextarea';

//https://www.npmjs.com/package/vue-draggable-resizable
import VueDraggableResizable from 'vue-draggable-resizable';

export const components = [
  RestyleMobile,
  btnPreload,
  Default,
  DefaultNew,
  Restyle,
  NoSidebar,
  Empty,
  Alert,
  Header,
  Footer,
  Loader,
  Modal,
  ModalConfirm,
  ModalTrash,
  ModalCustom,
  Popover,
  Tooltip,
  ProgressBar,
  Switchery,
  Panel,
  Tabs,
  Tab,
  Pagination,
  portalPagination,
  List,
  ListItem,
  restyle_button,
  restyle_alert,
  PaginationRestyle,
  IconWasteType,
  icon_button,
  switch_checkbox,
  RestyleInput,
  RestyleSelect,
  RestyleModal,
  RestyleTooltip,
  RestyleTextarea,
  VueDraggableResizable,
];